// LanguageSwitcher.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReactCountryFlag from 'react-country-flag';

const languageNames = {
  pl: <ReactCountryFlag style={{
    width: '1.5em',
    height: '1.5em',
    marginRight: "auto",marginLeft: "auto", display: "block"
}} countryCode="PL" svg />,
  de: <ReactCountryFlag style={{
    width: '1.5em',
    height: '1.5em',
    marginRight: "auto",marginLeft: "auto", display: "block"
}} countryCode="DE" svg />,
};

const LanguageSwitcherMobile = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    // Save the selected language to localStorage
    localStorage.setItem('selectedLanguage', selectedLanguage);
   // window.location.reload(false);
  };

  const supportedLanguages = i18n.options.supportedLngs.filter((lng) => lng !== 'cimode');

  useEffect(() => {
    // Retrieve the selected language from localStorage on component mount
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n, supportedLanguages]);

  return (
    <FormControl sx={{width: "20vw", textAlign: "center"}}>
      <Select 
       
        sx={{
          fontSize: '23px',
          color: "black",
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '.MuiSvgIcon-root ': {
            fill: "black !important",
          }
        }}
        
       
        
       
        id="language-switcher-mobile"
        value={i18n.language}
        onChange={changeLanguage}
      >
        {supportedLanguages.map((lng) => (
          <MenuItem   sx={{textAlign: "center"}} key={lng} value={lng}>
            {languageNames[lng]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcherMobile;
