import React, { useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import "dayjs/locale/de";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import myLogo3 from "../images/logo3.png";import axios from 'axios'; 
import "../styles/Form.css";

import { MuiTelInput } from "mui-tel-input";
import validator from "validator";




  



  

const CompanyForm = () => {
  

  const [agree, setAgree] = useState(false);
  

  const [phone, setPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const handleChange = (newPhone) => {
    setPhone(newPhone);
    setCountryCode(newPhone.split(" ")[0]);
  };

  const [message, setMessage] = useState("");

  const validateEmail = (e) => {
    const email = e.target.value;

    if (validator.isEmail(email)) {
      setMessage("");
    } else {
      setMessage("Geben Sie eine gültige E-Mail Adresse ein");
    }
  };

  
  

  


  

  
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    email: "",
    job: "",
    date: "",
    pay: "",
    hours: "",
    employment: "",
    duration: "",
    languages: "",
    driver: "",
    qualifications: "",
    housing: "",
    travel: "",
  });

  

 

 

 

  

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [isCheckBox, setIsCheckBox] = useState(false);
  const [agreeError, setAgreeError] = useState("");


  
  const handleButtonClick = () => {
    setAgree(!agree);
    setAgreeError("")
    setIsCheckBox(!isCheckBox);


  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

 
  const handleSubmit = async () => {

    
    const name = document.getElementById("name").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const email = document.getElementById("email").value.trim();

    const job = document.getElementById("job").value.trim();
    const date = selectedDate;
    const pay = document.getElementById("pay").value.trim();
    const hours = document.getElementById("hours").value.trim();
    const employment = document.getElementById("employment").value.trim();
    const duration = document.getElementById("duration").value.trim();
    const languages = document.getElementById("languages").value.trim();
    const driver = document.getElementById("driver").value.trim();
    const qualifications = document.getElementById("qualifications").value.trim();
    const housing = document.getElementById("housing").value.trim();
    const travel = document.getElementById("travel").value.trim();
    const comment = document.getElementById("comment").value.trim();


    setErrorMessages({
      name: name ? "" : "Dieses Feld ist erforderlich",
      phone: phone ? "" : "Dieses Feld ist erforderlich",
      email: email ? "" : "Dieses Feld ist erforderlich",
      job: job ? "" : "Dieses Feld ist erforderlich",
      date: date ? "" : "Dieses Feld ist erforderlich",
      pay: pay ? "" :  "Dieses Feld ist erforderlich",
      hours: hours ? "" : "Dieses Feld ist erforderlich",
      employment: employment ? "" : "Dieses Feld ist erforderlich",
      duration: duration ? "" : "Dieses Feld ist erforderlich",
      languages: languages ? "" :  "Dieses Feld ist erforderlich",
      driver: driver ? "" : "Dieses Feld ist erforderlich",
      qualifications: qualifications ? "" : "Dieses Feld ist erforderlich",
      housing: housing ? "" : "Dieses Feld ist erforderlich",
      travel: travel ? "" : "Dieses Feld ist erforderlich",
    });


      
    
    // Check for any errors
    const hasErrors = email && phone && name && job && date && pay && hours && employment && duration && languages && driver && qualifications && housing && travel ? false : true;
    
    if (!hasErrors && agree) {
      const sqlDate = selectedDate.$y +'-'+ (selectedDate.$M + 1) +'-'+selectedDate.$D;
      
      const formData = {
        name: name,
        phone: countryCode + " " + phone,
        email: email,

        job: job,
        date: sqlDate,
        pay: pay,
        hours: hours,
        employment: employment,
        duration: duration,
        languages: languages,
        driver: driver,
        qualifications: qualifications,
        housing: housing,
        travel: travel,
        comment: comment,
      };
     console.log(formData);
      try {
        const response = await axios.post("https://bavaria-job.com:35100/api/submitCompanyForm", formData, {
          headers: {
            'Content-Type': 'application/json', // Set content type to JSON
          },
        });
    
  
        if (response.data.success) {
          alert("Das Formular wurde abgeschickt.");
          window.location.reload(false);
        } else {
          alert("Das Formular wurde nicht abgeschickt.");
        }
      } catch (error) {
        console.error("Error sending form data: ", error);
      }
    
    
      
    }
    else{
      if(!agree){
        setAgreeError("Zustimmung ist erforderlich")
      }
    }
    
  };

  return (
    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Typography
          variant="h5"
          color="text.primary"
          gutterBottom
          align="center"
          sx={{
            mt: { lg: "3vh", md: "3vh", xs: "2vh" },
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            style={{ display: "block" }}
            height={50}
            width={71}
            src={myLogo3}
            alt="Logo"
          ></img>
        </Typography>
        <Paper
          elevation={4}
          sx={{
            mb: "4vh",
            mt: { lg: "3vh", md: "2.75vh", xs: "2.75vh" },
            mx: { lg: "20vw", md: "10vw", xs: "5vw" },
            p: "2.5vw",
          }}
        >
          <FormControl>
          <Typography textAlign="center" variant="h5" color="text.primary">
            Sehr geehrte Kundin, sehr geehrter Kunde
            </Typography>
            <Typography textAlign="justify" variant="h7" color="text.secondary">
            aufgrund der aktuellen Lage auf dem Arbeitsmarkt (mehrere Unternehmen suchen nach qualifizierten Arbeitskräften)
müssen wir den Bewerbern genaue, wahrheitsgemäße Arbeits- und Lohnbedingungen darstellen können. Zu diesem
Zweck bitten wir Sie, die beigefügte Tabelle mit möglichst detaillierten Angaben auszufüllen.
            </Typography>
           
            <br></br>

            <Typography variant="h5" color="text.primary">
            Firmenname
            </Typography>
          
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="name"
              label="Firmenname"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.job}
            </span>
            <br></br>




            <Typography variant="h5" color="text.primary">
            Telefonnummer
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Bitte die Telefonnummer mit der Vorwahl angeben.
            </Typography>
            <br></br>

            <MuiTelInput
              forceCallingCode
              defaultCountry="DE"
              onlyCountries={['FR', 'CZ', 'PL', 'DE', 'IT', 'GB', 'SK', 'HU', 'AT']} 
              sx={{ width: { lg: "30vw" } }}
              value={phone}
              onChange={handleChange}
              id="phone"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.phone}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            E-Mail-Adresse
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Bitte die E-Mail-Adresse angeben.
            </Typography>
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="email"
              label="E-Mail"
              variant="outlined"
              onChange={(e) => validateEmail(e)}
            />
            <span
              style={{
                color: "red",
              }}
            >
              {message}
            </span>
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.email}
            </span>
            <br></br>






            <Typography variant="h5" color="text.primary">
            Arbeitsstelle
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Kurzbeschreibung/Haupttätigkeiten, gewünschte
Personenzahl
            </Typography>
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="job"
              label="Arbeitsstelle"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.job}
            </span>
            <br></br>


            





            <Typography variant="h5" color="text.primary">
            Einsatzbeginn
            </Typography>
            <Typography variant="h7" color="text.secondary">
              Ab wann kann die Arbeit beginnen?
            </Typography>
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                id="date"
                sx={{ width: { lg: "30vw" } }}
                label="Datum"
              />
            </LocalizationProvider>
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.date}
            </span>
            <br></br>

          
            <Typography variant="h5" color="text.primary">
            Vergütung
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Nettostundenlohn, Sonderzahlungen, Zulagen – falls ja,
welche?
            </Typography>
            <br></br>
            <TextField
              fullWidth
              rows={5}
              multiline
              id="pay"
              label="            Vergütung
              "
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.pay}
            </span>
            <br></br>
            <Typography variant="h5" color="text.primary">
            Arbeitsstunden wöchentlich
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Arbeitszeiten, Arbeitssystem – z.B. Schichtarbeit
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="hours"
              label="Arbeitsstunden wöchentlich"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.hours}
            </span>
            <br></br>
           


            <Typography variant="h5" color="text.primary">
            Beschäftigungsart
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Vollzeit/Teilzeit, befristet/unbefristet
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="employment"
              label="Beschäftigungsart"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.employment}
            </span>
            <br></br>



            <Typography variant="h5" color="text.primary">
            Beschäftigungsdauer
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Für wie lange benötigen Sie die Arbeitskräfte?
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="duration"
              label="Beschäftigungsdauer"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.duration}
            </span>
            <br></br>



            <Typography variant="h5" color="text.primary">
            Fremdsprachenkenntnisse
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Erforderliches Sprachniveau in Wort und/oder in Schrift
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="languages"
              label="Fremdsprachenkenntnisse"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.languages}
            </span>
            <br></br>



            <Typography variant="h5" color="text.primary">
            Führerschein, eigener Wagen
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Ja/Nein?
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="driver"
              label="Führerschein, eigener Wagen"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.driver}
            </span>
            <br></br>
           
           
            <Typography variant="h5" color="text.primary">
            Erforderliche Qualifikationen
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Erlernte / Erworbene – ggf. gewünschte Erfahrungszeit
            </Typography>
            <br></br>
            <TextField
              fullWidth
              rows={5}
              multiline
              id="qualifications"
              label="            Erforderliche Qualifikationen
              "
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.qualifications}
            </span>
            <br></br>
       



            <Typography variant="h5" color="text.primary">
            Unterkunft
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Kostenabrechnung
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="housing"
              label="Unterkunft"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.housing}
            </span>
            <br></br>



            <Typography variant="h5" color="text.primary">
            Arbeitsanfahrt
            </Typography>
            <Typography variant="h7" color="text.secondary">
            Kostenabrechnung für erste Anfahrt/tägliche Anfahrt
            </Typography>
            
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="travel"
              label="Arbeitsanfahrt"
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.travel}
            </span>
            <br></br>
           




            <Typography variant="h5" color="text.primary">
            Ihre Anmerkungen
            </Typography>
           
            <br></br>
            <TextField
              fullWidth
              rows={5}
              multiline
              id="comment"
              label="            Ihre Anmerkungen
              "
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.comment}
            </span>
            <br></br>





          </FormControl>
         
         
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckBox}
                onChange={handleButtonClick}
                color="primary"
              />
            }
            label={
              <Typography sx={{ width: { lg: "35vw" } }} variant="body2">
                {" "}
                <span className="customLink"
                 
                  onClick={handleDialogOpen}
                >
                  Hiermit beauftrage ich die Jobsuche für die
          oben genannte Arbeitsstelle und die
          Übermittlung personenbezogener Daten an
          
          Personalvermittler. <br></br> (Hier klicken, um die Datenschutzbestimmungen

zu lesen)
                </span>
              </Typography>
            }
          />
          
         
          
          
          
          <br></br><span
              style={{
                color: "red",
              }}
            >
              {agreeError}
            </span>
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>Datenschutzbestimmungen</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
              Hiermit stimme ich der Verarbeitung meiner personenbezogenen Daten durch die Firma
          SPOT mit Sitz in: 43-300 Bielsko-Biała, Żółkiewskiego 6, für die für das
          Einstellungsverfahren erforderlichen Zwecke gemäß der Verordnung (EU) 2016/679 des
          Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher
          Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur
          Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung) zu.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
              Exit
              </Button>
            </DialogActions>
          </Dialog>
          <br></br>
          <br></br>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={handleSubmit}
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
            >
           SENDEN
            </Button>
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default CompanyForm;
