import React from "react";
import "../styles/Home.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import myLogo3 from '../images/logo3.png'
import { useTranslation } from 'react-i18next';





  




const Employee = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h5" color="text.primary" gutterBottom align="center" sx={{mt: {lg: "3vh", md: "3vh", xs:"2vh"},display: 'flex', justifyContent: 'center', width: '100%'}}>
      <img
          
          style={{display : 'block',}}
            height={50}
            width={71}
            src={myLogo3} alt="Logo"></img>
      </Typography>

      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
        {t('employee0')}
      </Typography>


      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
       
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"},
          textAlign: "justify"
        }}
      >
        
        {t('employee1')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
        
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"},  textAlign: "justify"
        }}
      >
     
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
        {t('employee2')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
        
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
        {t('employee3')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
        {t('employee4')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
       
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
      {t('employee5')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
       {t('employee6')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
        
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
        {t('employee7')}
        
      </Typography>



      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
       {t('employee8')}
      </Typography>
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
        
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "4vh", md: "4vh", xs: "4vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
       {t('employee9')}
        
        
      </Typography>
    </Box>
  );
};

export default Employee;
