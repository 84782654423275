// Dashboard.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import RecordsTable from './RecordsTable';
import CompanyTable from './CompanyTable'; // Import the CompanyTable component

const Dashboard = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const [showRecordsTable, setShowRecordsTable] = useState(true);

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return null;
  }

  const handleSwitchTable = () => {
    // Toggle between RecordsTable and CompanyTable
    setShowRecordsTable((prev) => !prev);
  };

  return (
    <div>
      <div style={{marginTop: "2.5vh"}}>
        
        <Button sx={{ml: "1vw", width: "7vw", mb: "1.25vh", float: "right"}} variant="contained" onClick={handleSwitchTable}>
          {showRecordsTable ? 'Firmy' : 'Klienci'}
        </Button>
       
      </div>
      {showRecordsTable ? <RecordsTable /> : <CompanyTable />}
    </div>
  );
};

export default Dashboard;
