import React, { useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Button,
  Checkbox,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import "dayjs/locale/pl";
import "dayjs/locale/de";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MuiTelInput } from "mui-tel-input";
import validator from "validator";
import myLogo3 from "../images/logo3.png";import axios from 'axios'; 
import "../styles/Form.css";
import { useTranslation } from 'react-i18next';





  



  
const Form = () => {
  const { t } = useTranslation();
  const [agree, setAgree] = useState(false);
  const [isLicense, setIsLicense] = useState(true);
  const [isCheckedLicenseB, setIsCheckedLicenseB] = useState(false);
  const [isCheckedLicenseC, setIsCheckedLicenseC] = useState(false);





  
  const handleChangeLicense = () => {
    setIsLicense(true);
    if (isCheckedLicenseB) {
      setIsCheckedLicenseB(!isCheckedLicenseB);
    }
    if (isCheckedLicenseC) {
      setIsCheckedLicenseC(!isCheckedLicenseC);
    }
  };

  const handleChangeCheckedLicenseB = () => {
    setIsCheckedLicenseB(!isCheckedLicenseB);
    if (isLicense) {
      setIsLicense(!isLicense);
    }
    if (isCheckedLicenseB === true && isCheckedLicenseC === false) {
      setIsLicense(true);
    }
  };
  const [cvButton, setCvButton] = React.useState("");
  
  const [pdfCheck, setPdfCheck] = React.useState(true);
  const handleChangeCV = (event) => {
     try {
      setCvButton(event.target.files[0].name);
     }catch(error){
      setCvButton("");

     }
   try {
    if (event.target.files[0].type !== "application/pdf") {
      setPdfCheck(false);
      setMessageCV(t('error2'));
    } else {
      setMessageCV("");
      setPdfCheck(true);
    }
   }catch(error){

   }
    
  };

  const handleChangeCheckedLicenseC = () => {
    setIsCheckedLicenseC(!isCheckedLicenseC);
    if (isLicense) {
      setIsLicense(!isLicense);
    }
    if (isCheckedLicenseB === false && isCheckedLicenseC === true) {
      setIsLicense(true);
    }
  };

  const [phone, setPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const handleChange = (newPhone) => {
    setPhone(newPhone);
    setCountryCode(newPhone.split(" ")[0]);
  };

  const [message, setMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    job: "",
    date: "",
    qualifications: "",
    skills: "",
    pay: "",
    name: "",
    surname: "",
    age: "",
    phone: "",
    email: "",
  });

  const validateEmail = (e) => {
    const email = e.target.value;

    if (validator.isEmail(email)) {
      setMessage("");
    } else {
      setMessage(t('error3'));
    }
  };

  const [messageCV, setMessageCV] = useState("");

  const [showRadioGroup1, setShowRadioGroup1] = useState(false);
  const [selectedValue1, setSelectedValue1] = useState("");

  const [showRadioGroup2, setShowRadioGroup2] = useState(false);
  const [selectedValue2, setSelectedValue2] = useState("");

  const [showRadioGroup3, setShowRadioGroup3] = useState(false);
  const [selectedValue3, setSelectedValue3] = useState("");

  const [showRadioGroup4, setShowRadioGroup4] = useState(false);
  const [selectedValue4, setSelectedValue4] = useState("");

  const handleCheckboxChange = (groupNumber) => {
    switch (groupNumber) {
      case 1:
        setShowRadioGroup1(!showRadioGroup1);
        setSelectedValue1("");
        break;
      case 2:
        setShowRadioGroup2(!showRadioGroup2);
        setSelectedValue2("");
        break;
      case 3:
        setShowRadioGroup3(!showRadioGroup3);
        setSelectedValue3("");
        break;
      case 4:
        setShowRadioGroup4(!showRadioGroup4);
        setSelectedValue4("");
        break;
      default:
        break;
    }
  };

  const handleRadioChange = (groupNumber) => (event) => {
    switch (groupNumber) {
      case 1:
        setSelectedValue1(event.target.value);
        break;
      case 2:
        setSelectedValue2(event.target.value);
        break;
      case 3:
        setSelectedValue3(event.target.value);
        break;
      case 4:
        setSelectedValue4(event.target.value);
        break;
      default:
        break;
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [isCheckBox, setIsCheckBox] = useState(false);
  const [agreeError, setAgreeError] = useState("");


  
  const handleButtonClick = () => {
    setAgree(!agree);
    setAgreeError("")
    setIsCheckBox(!isCheckBox);


  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [selectedValueCar, setSelectedValueCar] = useState("nie");
  

  const handleChangeCar = (event) => {
    setSelectedValueCar(event.target.value);
  };

  const handleSubmit = async () => {

    
    let licence = "";
    if (isLicense) {
      licence = "nie";
    } else {
      if (isCheckedLicenseB && isCheckedLicenseC) {
        licence = "B + inne";
      } else {
        if (isCheckedLicenseB) {
          licence = "B";
        }
        if (isCheckedLicenseC) {
          licence = "inne";
        }
      }
    }

    const job = document.getElementById("job").value.trim();
    
    const date = selectedDate;
    const qualifications = document
      .getElementById("qualifications")
      .value.trim();
    const skills = document.getElementById("skills").value.trim();
    const english = selectedValue1.trim();
    const german = selectedValue2.trim();
    const italian = selectedValue3.trim();
    const french = selectedValue4.trim();
    licence = licence.trim();
    const car = selectedValueCar.trim();
    const pay = document.getElementById("pay").value.trim();
    const name = document.getElementById("name").value.trim();
    const surname = document.getElementById("surname").value.trim();
    const age = document.getElementById("age").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const email = document.getElementById("email").value.trim();
    const cv = document.getElementById("cv").files[0];

    setErrorMessages({
      job: job ? "" : t('error'),
      date: date ? "" : t('error'),
      qualifications: qualifications ? "" : t('error'),
      skills: skills ? "" : t('error'),
      pay: pay ? "" : t('error'),
      name: name ? "" : t('error'),
      surname: surname ? "" : t('error'),
      age: age ? "" : t('error'),
      phone: phone ? "" : t('error'),
      email: email ? "" : t('error'),
    });


      
    
    // Check for any errors
    const hasErrors = job && date && qualifications && skills && pay && name && surname && age && phone && email ? false : true;
    
    if (pdfCheck && !hasErrors && agree) {
      const sqlDate = selectedDate.$y +'-'+ (selectedDate.$M + 1) +'-'+selectedDate.$D;
      
      const formData = new FormData();
      formData.append('id', 1);
      formData.append('date', sqlDate);
      formData.append('job', job);
      formData.append('qualifications', qualifications);
      formData.append('skills', skills);
      formData.append('english', english);
      formData.append('german', german);
      formData.append('italian', italian);
      formData.append('french', french);
      formData.append('licence', licence);
      formData.append('car', car);
      formData.append('pay', pay);
      formData.append('name', name);
      formData.append('surname', surname);
      formData.append('age', age);
      formData.append('phone', countryCode + " " + phone);
      formData.append('email', email);
      formData.append('cv', cv);
      
  
      try {
        const response = await axios.post("https://bavaria-job.com:35100/api/submitForm", formData);
  
        if (response.data.success) {
          alert(t("alert0"));
          window.location.reload(false);
        } else {
          alert(t("alert1"));
        }
      } catch (error) {
        //console.error("Error sending form data: ", error);
      }
    
    
      
    }
    else{
      if(!agree){
        setAgreeError(t('error1'))
      }
    }
    
  };

  return (
    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Typography
          variant="h5"
          color="text.primary"
          gutterBottom
          align="center"
          sx={{
            mt: { lg: "3vh", md: "3vh", xs: "2vh" },
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            style={{ display: "block" }}
            height={50}
            width={71}
            src={myLogo3}
            alt="Logo"
          ></img>
        </Typography>
        <Paper
          elevation={4}
          sx={{
            mb: "4vh",
            mt: { lg: "3vh", md: "2.75vh", xs: "2.75vh" },
            mx: { lg: "20vw", md: "10vw", xs: "5vw" },
            p: "2.5vw",
          }}
        >
          <FormControl>
            <Typography variant="h5" color="text.primary">
              {t('form0')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
              {t('form1')}
            </Typography>
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="job"
              label={t('form2')}
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.job}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form3')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form4')}
            </Typography>
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={t("lang")}>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                id="date"
                sx={{ width: { lg: "30vw" } }}
                label={t('form5')}
              />
            </LocalizationProvider>
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.date}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form7')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form8')}
            </Typography>
            <br></br>
            <TextField
              fullWidth
              rows={5}
              multiline
              id="qualifications"
              label={t('form9')}
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.qualifications}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form10')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form11')}
            </Typography>
            <br></br>
            <TextField
              fullWidth
              rows={5}
              multiline
              id="skills"
              label={t('form12')}
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.skills}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form13')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form14')}
            </Typography>
            <br></br>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRadioGroup1}
                    onChange={() => handleCheckboxChange(1)}
                  />
                }
                label={t('form15')}
              />
              {showRadioGroup1 && (
                <FormControl
                  component="fieldset"
                  sx={{ color: "text.secondary", mx: "3vw" }}
                >
                  <FormLabel component="legend">{t('form16')}</FormLabel>
                  <RadioGroup
                    value={selectedValue1}
                    id="english"
                    onChange={handleRadioChange(1)}
                  >
                    <FormControlLabel
                      id="englishBasic"
                      value="podstawowy"
                      control={<Radio />}
                      label={t('form17')}
                    />
                    <FormControlLabel
                      id="englishAdvanced"
                      value="komunikatywny"
                      control={<Radio />}
                      label={t('form18')}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRadioGroup2}
                    onChange={() => handleCheckboxChange(2)}
                  />
                }
                label={t('form19')}
              />
              {showRadioGroup2 && (
                <FormControl
                  component="fieldset"
                  sx={{ color: "text.secondary", mx: "3vw" }}
                >
                  <FormLabel component="legend">{t('form16')}</FormLabel>
                  <RadioGroup
                    id="german"
                    value={selectedValue2}
                    onChange={handleRadioChange(2)}
                  >
                    <FormControlLabel
                      id="germanBasic"
                      value="podstawowy"
                      control={<Radio />}
                      label={t('form17')}
                    />
                    <FormControlLabel
                      id="germanAdvanced"
                      value="komunikatywny"
                      control={<Radio />}
                      label={t('form18')}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRadioGroup3}
                    onChange={() => handleCheckboxChange(3)}
                  />
                }
                label={t('form20')}
              />
              {showRadioGroup3 && (
                <FormControl
                  component="fieldset"
                  sx={{ color: "text.secondary", mx: "3vw" }}
                >
                  <FormLabel component="legend">{t('form16')}</FormLabel>
                  <RadioGroup
                    id="italian"
                    value={selectedValue3}
                    onChange={handleRadioChange(3)}
                  >
                    <FormControlLabel
                      id="italianBasic"
                      value="podstawowy"
                      control={<Radio />}
                      label={t('form17')}
                    />
                    <FormControlLabel
                      id="italianAdvanced"
                      value="komunikatywny"
                      control={<Radio />}
                      label={t('form18')}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRadioGroup4}
                    onChange={() => handleCheckboxChange(4)}
                  />
                }
                label={t('form21')}
              />
              {showRadioGroup4 && (
                <FormControl
                  component="fieldset"
                  sx={{ color: "text.secondary", mx: "3vw" }}
                >
                  <FormLabel component="legend">{t('form16')}</FormLabel>
                  <RadioGroup
                    id="french"
                    value={selectedValue4}
                    onChange={handleRadioChange(4)}
                  >
                    <FormControlLabel
                      id="frenchBasic"
                      value="podstawowy"
                      control={<Radio />}
                      label={t('form17')}
                    />
                    <FormControlLabel
                      id="frenchAdvanced"
                      value="komunikatywny"
                      control={<Radio />}
                      label={t('form18')}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </FormGroup>

            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form22')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form23')}
            </Typography>
            <br></br>
            <FormGroup id="licence">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLicense}
                    onChange={handleChangeLicense}
                  />
                }
                value="nie"
                label={t('form24')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedLicenseB}
                    onChange={handleChangeCheckedLicenseB}
                  />
                }
                value="B"
                label={t('form25')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedLicenseC}
                    onChange={handleChangeCheckedLicenseC}
                  />
                }
                value="Inne"
                label={t('form26')}
              />
            </FormGroup>
            <br></br>

            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
              {t('form27')}
              </FormLabel>
              <RadioGroup
                
                onChange={handleChangeCar}
                id="car"
                defaultValue="nie"
              >
                <FormControlLabel value="nie" label={t('form28')} control={<Radio  />} />
                <FormControlLabel value="tak" label={t('form28part')} control={<Radio />} />
              </RadioGroup>
            </FormControl>
            <br></br>
            <Typography variant="h5" color="text.primary">
            {t('form29')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form30')}
            </Typography>
            <br></br>
            <TextField
              fullWidth
              rows={5}
              multiline
              id="pay"
              label={t('form31')}
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.pay}
            </span>
            <br></br>
            <Typography variant="h5" color="text.primary">
            {t('form32')}
            </Typography>

            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="name"
              label={t('form32')}
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.name}
            </span>
            <br></br>
            <Typography variant="h5" color="text.primary">
            {t('form33')}
            </Typography>
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="surname"
              label={t('form33')}
              variant="outlined"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.surname}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form34')}
            </Typography>
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="age"
              label={t('form34')}
              variant="outlined"
              type="number"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.age}
            </span>
            <br></br>
            <Typography variant="h5" color="text.primary">
            {t('form35')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form36')}
            </Typography>
            <br></br>

            <MuiTelInput
              forceCallingCode
              defaultCountry="PL"
              onlyCountries={['FR', 'CZ', 'PL', 'DE', 'IT', 'GB', 'SK', 'HU', 'AT']} 
              sx={{ width: { lg: "30vw" } }}
              value={phone}
              onChange={handleChange}
              id="phone"
            />
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.phone}
            </span>
            <br></br>

            <Typography variant="h5" color="text.primary">
            {t('form37')}
            </Typography>
            <Typography variant="h7" color="text.secondary">
            {t('form38')}
            </Typography>
            <br></br>
            <TextField
              sx={{ width: { lg: "30vw" } }}
              id="email"
              label={t('form39')}
              variant="outlined"
              onChange={(e) => validateEmail(e)}
            />
            <span
              style={{
                color: "red",
              }}
            >
              {message}
            </span>
            <span
              style={{
                color: "red",
              }}
            >
              {errorMessages.email}
            </span>
            <br></br>
          </FormControl>
          <Typography variant="h5" color="text.primary">
          {t('form40')}
          </Typography>
          <Typography variant="h7" color="text.secondary">
          {t('form41')}
          </Typography>
          <br></br>
          <br></br>
          <Button variant="contained" component="label">
          {t('form42')}: {cvButton}
            <input
              id="cv"
              accept=".pdf"
              onChange={handleChangeCV}
              type="file"
              hidden
            />
          </Button>{" "}
          <br></br>
          <span
            style={{
              color: "red",
            }}
          >
            {messageCV}
          </span>
          <br></br>
          <br></br>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckBox}
                onChange={handleButtonClick}
                color="primary"
              />
            }
            label={
              <Typography sx={{ width: { lg: "35vw" } }} variant="body2">
                {" "}
                <span className="customLink"
                 
                  onClick={handleDialogOpen}
                >
                 {t('form43')} <br></br> {t('form44')} 
                </span>
              </Typography>
            }
          />
          
         
          
          
          
          <br></br><span
              style={{
                color: "red",
              }}
            >
              {agreeError}
            </span>
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle> {t('policy0')}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
              {t('policy1')}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
              {t('policy2')}
              </Button>
            </DialogActions>
          </Dialog>
          <br></br>
          <br></br>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={handleSubmit}
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
            >
               {t('form45')} 
            </Button>
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default Form;
