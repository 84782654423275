
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DrawerAppBar from "./components/DrawerAppBar";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Form from "./components/Form";
import Employee from "./components/Employee";
import Footer from "./components/Footer";
import Dashboard from './components/Dashboard';
import Login from "./components/Login";
import ScrollToTop from "./components/ScrollToTop";
import CompanyForm from "./components/CompanyForm";
import Company from "./components/Company";
import './i18n';




function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check for authentication status in localStorage on app initialization
    const storedAuthStatus = localStorage.getItem('isAuthenticated');
    if (storedAuthStatus === 'true') {
      setAuthenticated(true);
    }
  }, []);

  // Function to determine whether to show the Footer based on the current route
  const shouldRenderFooter = () => {
    return location.pathname !== '/dashboard';
  };

  return (
    <div className="App">
      <DrawerAppBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="form" element={<Form />} />
        <Route path="company_form" element={<CompanyForm />} />
        <Route path="employee" element={<Employee />} />
        <Route path="employer" element={<Company />} />
        <Route path="login" element={<Login setAuthenticated={setAuthenticated} />} />
        <Route
          path="dashboard"
          element={<Dashboard isAuthenticated={isAuthenticated} />}
        />
        <Route path="*" element={<Home />} />
        
      </Routes>
      {shouldRenderFooter() && <Footer />}
    </div>
  );
}

export default App;
