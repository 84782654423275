import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  CssBaseline,
  Typography,
  styled,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledContainer = styled(Container)({
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: 1,
});

const StyledButton = styled(Button)({
  margin: '3px 0 2px',
});

const Login = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    if (username === 'januter' && password === 'Janusz1974++') {
      localStorage.setItem('isAuthenticated', 'true');
      setAuthenticated(true);
      navigate('/dashboard'); // Redirect to the protected page
    } else {
      setAuthenticated(false);
    }
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is Enter
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <StyledContainer sx={{ height: '66.5vh' }} component="main" maxWidth="xs">
      <CssBaseline />
      <Typography sx={{ mt: '18vh' }} component="h1" variant="h5">
        Admin Login
      </Typography>
      <StyledForm noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Login"
          name="Login"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="hasło"
          label="Hasło"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyPress} // Call handleKeyPress on key down
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <StyledButton
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          Zaloguj
        </StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default Login;
