import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { HashLink as Link } from "react-router-hash-link";
import myLogo from '../images/logo.svg'
import myLogo2 from '../images/logo2.svg'
import LanguageSwitcher from './LanguageSwitcher.js'
import LanguageSwitcherMobile from './LanguageSwitcherMobile.js'
import { useTranslation } from 'react-i18next';




function DrawerAppBar(props) {
  const { t } = useTranslation();
  const drawerWidth = 240;
const navItems = [
  { id: 1, name: t('home'), route: "home" },
  { id: 2,  name: t('form'), route: "form" },
  { id: 3,  name: t('employee'), route: "employee" },
  { id: 4,  name: "FÜR ARBEITGEBER", route: "employer" },
];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography  variant="h6" sx={{ my: 2,display: 'flex', justifyContent: 'center', width: '100%' }}>
      <img
          
           style={{display : 'block'}}
             height={30}
             width={"auto"}
             src={myLogo2} alt="Logo"></img>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              component={Link}
              to={"/" + item.route}
              sx={{ textAlign: "center"}}
            >
              <ListItemText  primaryTypographyProps={{fontSize: '23px'}}  primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <LanguageSwitcherMobile ></LanguageSwitcherMobile>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          > 
            <img
           style={{display : 'block'}}
             height={30}
             width={"auto"}
             src={myLogo} alt="Logo"></img>
          </Typography>
          <Box  style={{
        
       
        alignItems: 'center',
        justifyContent: 'center',
        
      }} sx={{ display: { xs: "none", sm: "flex" } }}>
            {navItems.map((item) => (
              <Button
                
                component={Link}
                to={"/" + item.route}
                key={item.id}
                sx={{ color: "#fff", textAlign: "center"}}
              >
                <h3>{item.name}</h3>
              </Button>
            ))}
            <LanguageSwitcher></LanguageSwitcher>
          </Box>
          
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: {lg: 4.5, md: 4, xs: 4} }}></Box>
    </Box>
  );
}

export default DrawerAppBar;
