import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { format } from 'date-fns';


const RecordsTable = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://bavaria-job.com:35100/api/data/company');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (sortConfig.direction === 'ascending') {
      return (aValue || '').toString().localeCompare((bValue || '').toString());
    } else {
      return (bValue || '').toString().localeCompare((aValue || '').toString());
    }
  });

  const filterByColumn = (record) => {
    if (selectedColumn === 'all') {
      return Object.values(record).some((value) =>
        value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      return (
        record[selectedColumn] &&
        record[selectedColumn].toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  };

  const filteredData = sortedData.filter(filterByColumn);

  const renderHeaderCell = (key, label, width) => (
    <TableCell  onClick={() => handleSort(key)} sx={{ cursor: 'pointer', position: 'relative', width: `${width}%`, textAlign:"center" }}>
      {label}
      {sortConfig.key === key && (
        <div style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }}>
          {sortConfig.direction === 'ascending' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </div>
      )}
    </TableCell>
  );

  const handleRemoveRecord = async (id) => {
    const shouldDelete = window.confirm('Czy na pewno usunąć wpis?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }
    try {
      await axios.delete(`https://bavaria-job.com:35100/api/data/company/${id}`);
      // Update the data after successful deletion
      const updatedData = data.filter((record) => record.ID !== id);
      setData(updatedData);
    } catch (error) {
      console.error('Error removing record:', error);
    }
  };
  







  return (
    <div>
      <input
        type="text"
        placeholder="Wyszukaj..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ margin: '10px' }}
      />
      <label htmlFor="columns">Wyszukaj w: </label>
      <select
        id="columns"
        value={selectedColumn}
        onChange={(e) => setSelectedColumn(e.target.value)}
      >
        <option value="all">wszędzie</option>
        <option value="ID">ID</option>
        <option value="name">Nazwa</option>
        <option value="phone">Telefon</option>
        <option value="email">E-mail</option>
        <option value="job">Stanowisko</option>
        <option value="start_date">Data rozpoczęcia</option>
        <option value="pay">Płaca</option>
        <option value="work_hours">Godziny pracy</option>
        <option value="employment_type">Typ zatrudnienia</option>
        <option value="duration">Długość zatrudnienia</option>
        <option value="languages">Języki</option>
        <option value="driver_car">Samochód i prawo jazdy</option>
        <option value="required_qualifications">Kwalifikacje</option>
        <option value="accommodation">Akomodacja</option>
        <option value="travel">Trasa do pracy</option>
        <option value="comments">Komentarz</option>
      </select>
       <TableContainer component={Paper} sx={{ overflowX: 'auto', width:"225%" }}>
        <Table sx={{ tableLayout: 'fixed', width:"100%"}}>
          <TableHead >
            <TableRow >
              {renderHeaderCell('ID', 'ID', 5)}
              {renderHeaderCell('name', 'Nazwa', 10)}
              {renderHeaderCell('phone', 'Telefon', 10)}
              {renderHeaderCell('email', 'E-mail', 10)}
              {renderHeaderCell('job', 'Stanowisko', 10)}
              {renderHeaderCell('start_date', 'Data rozpoczęcia', 10)}
              {renderHeaderCell('pay', 'Płaca', 10)}
              {renderHeaderCell('work_hours', 'Godziny pracy', 10)}
              {renderHeaderCell('employment_type', 'Typ zatrudnienia', 10)}
              {renderHeaderCell('duration', 'Długość zatrudnienia', 10)}
              {renderHeaderCell('languages', 'Języki', 10)}
              {renderHeaderCell('driver_car', 'Samochód i prawo jazdy', 10)}
              {renderHeaderCell('required_qualifications', 'Kwalifikacje', 10)}
              {renderHeaderCell('accommodation', 'Akomodacja', 10)}
              {renderHeaderCell('travel', 'Trasa do pracy', 10)}
              {renderHeaderCell('comments', 'Komentarz', 10)}
              <TableCell sx={{width: "10%"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((record) => (
              <TableRow  key={record.ID}>
                <TableCell sx={{textAlign:"center"}}>{record.ID}</TableCell>
                


                <TableCell sx={{textAlign:"center"}}>{record.name}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.phone}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.email}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.job}</TableCell>

                <TableCell sx={{textAlign:"center"}}>
  {
    (() => {
      try {
        return format(new Date(record.start_date), 'yyyy-MM-dd');
      } catch (error) {
        
        return 'Błędna data';
      }
    })()
  }
</TableCell>

                <TableCell sx={{textAlign:"center"}}>{record.pay}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.work_hours}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.employment_type}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.duration}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.languages}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.driver_car}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.required_qualifications}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.accommodation}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.travel}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{record.comments}</TableCell>
               
                <TableCell sx={{textAlign:"center"}}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRemoveRecord(record.ID)}
                  >
                    Usuń
                  </Button>
                  
                </TableCell>
            
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RecordsTable;
