// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: {
        translation: {
          home: 'Strona główna',
          form: 'Formularz',
          employee: 'Dla pracowników',
          whatWeDo: 'O Bavaria Job',
          whatWeDoText: "Jesteśmy firmą doradztwa personalnego. " +
            "Wykorzystujemy nasze kilkuletnie " +
            "doświadczenie w branży. Z uwagi na dynamikę " +
            "europejskiego rynku pracy chcemy pomóc " +
            "zarówno osobom poszukującym pracy jak i " +
            "firmom potrzebującym pracowników. Nie " +
            "jesteśmy agencją pracy.",
          offer: 'Czym się zajmujemy?',
          offerText: `Naszym celem jest kojarzenie pracowników i
          pracodawców ze względu na wzajemne
          oczekiwania. Zgodnie z powyższym chcemy
          świadczyć nasze usługi dwóm grupom. Osobom
          poszukującym pracy pomagamy znaleźć
          właściwe miejsce zatrudnienia, stosowne do ich
          kwalifikacji i oczekiwań finansowych. Na
          obecnym etapie robimy to bezprowizyjnie,
          chyba że specyfika kwalifikacji czy stanowiska
          wiąże się ze szczególnym stopniem trudności.
          W takim przypadku zastrzegamy sobie
          możliwość negocjowania warunków naszej
          usługi.`,
          offerJob: 'Oferta dla pracodawców',
          offerJobText: `Warunki dla firm poszukujących pracowników
          ustalamy w zależności od stopnia
          skomplikowania procesu rekrutacji w czasie
          indywidualnych negocjacji. Proponujemy
          kojarzenie potrzeb pracowników z
          oczekiwaniami potencjalnych pracodawców.`,
          offerEmploy: 'Oferta dla szukających pracy',
          offerEmployText: `Zainteresowane pracą osoby prosimy o
          wypełnienie formularza identyfikującego ich
          potrzeby i możliwy termin podjęcia zatrudnienia.
          W oparciu o zgłoszone zapotrzebowanie
          wyszukujemy pracodawców oferujących
          adekwatne miejsca pracy i stanowiska.`,
          range: 'Zakres działania',
          rangeText: `Dysponując wieloletnim doświadczeniem w
          zakresie tłumaczeń i szkoleń językowych,
          możemy zaoferować naszym Klientom
          kompetentną pomoc w odnalezieniu się na
          zagranicznym rynku pracy. Obecnie działamy
          na rynku niemieckojęzycznym (Niemcy, Austria,
          Szwajcaria). W dalszej perspektywie będziemy
          rozwijali nasze kontakty na rynku francuskojęzycznym.`,
          companyInfo:`Dane firmy`,
          companyInfo1:`firma SPOT
          z siedzibą w:`,
          companyInfo2:`ul. Żółkiewskiego 6, 43-300 Bielsko-Biała, Polska`,
          companyInfo3:`Email: `,
          companyInfo4:`Numer telefonu: +48 728 542 306`,
          companyInfo5:`Numer w rejestrze: 30882`,
          employee0: `Forma Zatrudnienia`,
          employee1: `Wielu kandydatów do pracy ma już za sobą
          doświadczenia w pracy poza krajem, jednakże z
          uwagi na specyfikę niemieckiego rynku pracy
          kilka informacji, które mogą być przydatne.
          Zwykle pracownicy z Polski pracują dla
          niemieckich agencji pracy, to znaczy firm, które
          zatrudniają pracowników na umowę o pracę i
          podnajmują ich na potrzeby firm prowadzących
          budowy, czy też innego rodzaju działalność
          gospodarcza.`,
          employee1part: ` Zaleta to prosty układ – pracujemy
          na umowę o pracę, dostajemy wynagrodzenie,
          pracujemy pod kierownictwem i wykonujemy to,
          co nam polecono – zgodnie z naszymi
          kwalifikacjami, z jakimi zostaliśmy zatrudnieni.
          Zaleta to, że oprócz wynagrodzenia możemy
          liczyć na zwrot lub opłacenie kosztów
          zakwaterowania, lub ewentualną dietę, z której
          można pokryć koszt mieszkania, lub jeszcze się
          utrzymać przez jakiś czas - czyli więcej zostaje z
          wypłaty do domu, ewentualnie dodatek do
          kosztów dojazdu samochodem i inne bonusy. W
          przypadku każdego pracodawcy warunki mogą
          być nieco inne i są one jasno przedstawiane
          przed podpisaniem umowy o zatrudnienie, tak
          aby każdy wiedział, na co może liczyć. O innych
          formach, jak np. samozatrudnieniu nie piszemy,
          bowiem ilość tak zatrudnianych osób jest
          stanowczo mniejsza i w indywidualnych
          przypadkach stosujemy rozwiązania stosowne do
          
          potrzeb.`,
          employee2: `Język`,
          employee3: `Ludzie często pytają co ze znajomością języka
          - trzeba jasno powiedzieć, że znajomość
          języka jest bardzo potrzebna - zwykle podobnie
          jak u nas ludzie mówią w lokalnym języku i aby
          dobrze pracować i dawać sobie radę język jest
          potrzebny, dlatego każdy, kto pracuje za
          granicą, powinien umieć się dogadać - choćby
          załatwić podwyżkę z szefem. Oczywiście to
          byłoby idealnie, jednakże ze względu na realia,
          firmy starają się zatrudniać fachowców i w
          pewnych przypadkach można pracować bez
          znajomości języka, bo pracuje większą grupa
          ludzi i ktoś mówi po niemiecku, lub kierownik,
          lub majster mówi po polsku, angielsku, lub w
          jakimś innym języku i można się dogadać.
          Zwykle agencje pracy mają polskojęzycznych
          koordynatorów, którzy pomagają pracownikom.
          Zatem brak znajomości języka nie musi
          przekreślać szansy na zatrudnienie, ale jego
          znajomość znacząco pomaga i na początku i
          
          później w pracy.`,
          employee4: `Praca i Alkohol`,
          employee5: `Kolejny temat to alkohol. Jadą ludzie dorośli i
          nikt nikomu nie będzie wieczorem patrzył, co
          kto robi po pracy. Jednakże trzeba mieć
          świadomość, że często pracownicy
          przesadzają i alkohol powoduje problemy w
          miejscu zakwaterowania, oraz w pracy. Trzeba
          być świadomym, że wiele firm wprowadziło
          testowanie alkomatem w pracy, zaś praca pod
          wpływem alkoholu kończy się zwolnieniem
          dyscyplinarnym i karami finansowymi. Z uwagi
          na fakt, że wielokrotnie były i będą problemy z
          alkoholem, proszę potraktować to poważnie,
          aby uniknąć problemów. Najlepszy fachowiec
          odpada, jeśli pije i to, im wcześniej, tym lepiej
          dla niego - mniej to kosztuje.`,
          employee6: `Samochód`,
          employee7: `Prawo jazdy i własny samochód są potrzebne,
          bowiem często dojazd każdego dnia z kwatery
          do pracy jest znacznie ułatwiony dzięki
          własnemu pojazdowi. W przypadku braku auta
          często dojeżdża kilka osób razem i w ten
          sposób brak samochodu nie przekreśla
          możliwości pracy.`,
          employee8: `Podsumowanie`,
          employee9: `Poza praca dla agencji pracy osoby
          posiadające kwalifikacje zawodowe, znajomość
          języka mogą liczyć na zatrudnienie u
          pracodawców docelowych na warunkach takich
          jak ludzie mieszkający na stałe w Niemczech.
          Jednakże takich pracowników jest stanowczo
          mniej, zarabiają niekoniecznie lepiej i pracują
          stanowczo krócej. Normalny tydzień pracy to
          38-40 godzin tygodniowo, zaś pracując dla
          agencji pracy, można pracować i 60 godzin w
          tygodniu. Każdą kandydaturę traktujemy
          indywidualnie, poszukujemy dla kandydatów
          pracy zgodnie z ich oczekiwaniami i
          kwalifikacjami, a termin wyjazdu staramy się
          dopasować do oczekiwań i możliwości
          kandydatów, chociaż nie zawsze jest to
          możliwe i czasami trzeba poczekać, aby mieć
          
          dobrą pracę.`,
          form0: `Poszukiwane stanowisko pracy`,
          form1: `Proszę wprowadzić nazwę poszukiwanego

          stanowiska.`,
          form2: `Stanowisko`,
          form3: `Data podjęcia zatrudnienia`,
          form4: `Proszę wprowadzić datę od której może
          Pan/Pani rozpocząć pracę.`,
          form5: `Data`,
          form6: `DD.MM.YYYY`,
          form7: `Kwalifikacje`,
          form8: `Proszę wprowadzić swoje kwalifikacje
          zawodowe. Opisać swoje doświadczenie, ile lat
          pracuje Pan/Pani w zawodzie.`,
          form9: `Kwalifikacje`,
          form10: `Dodatkowe umiejętności`,
          form11: `Proszę wprowadzić swoje dodatkowe
          uprawnienia i umiejętności (kursy, szkolenia,
          
          licencje).`,
          form12: `Umiejętności`,
          form13: `Znajomość języka`,
          form14: `Proszę wprowadzić znane przez Pana/Panią

          języki obce.`,
          form15: `Angielski`,
          form16: `Poziom znajomości`,
          form17: `podstawowy`,
          form18: `komunikatywny`,
          form19: `Niemiecki`,
          form20: `Włoski`,
          form21: `Francuski`,
          form22: `Prawo jazdy`,
          form23: `Czy posiada Pan/Pani prawo jazdy, jeżeli tak to

          jakiej kategorii.`,
          form24: `Nie posiadam.`,
          form25: `Kategoria B`,
          form26: `Inne`,
          form27: `Czy posiada Pan/Pani samochód?`,
          form28: `Nie`,
          form28part: `Tak`,
          form29: `Oczekiwania finansowe`,
          form30: `Proszę wprowadzić Pana/Pani oczekiwania

          finansowe.`,
          form31: `Oczekiwania`,
          form32: `Imię`,
          form33: `Nazwisko`,
          form34: `Wiek`,
          form35: `Telefon kontaktowy`,
          form36: `Proszę podać telefon kontaktowy wraz z
          numerem kierunkowym.`,
          form37: `​Adres e-mail`,
          form38: `Proszę wprowadzić adres e-mail`,
          form39: `E-mail`,
          form40: `CV (opcjonalne)`,
          form41: `Proszę załączyć plik PDF zawierające CV.`,
          form42: `ZAŁĄCZ PLIK`,
          form43: `Niniejszym zlecam poszukiwanie pracy na wyżej
          wymienionym stanowisku oraz przekazanie
          danych osobowych firmom poszukującym
          
          pracowników.`,
          form44: `(kliknij aby przeczytać politykę prywatności)`,
          form45: `AKCEPUTJĘ`,
          form46: `WYŚLIJ`,
          policy0: `Polityka Prywatności`,
          policy1:`Wyrażam zgodę na przetwarzanie moich danych osobowych przez firmę SPOT z siedzibą w: 43-300 Bielsko-Biała, ul. Żółkiewskiego 6, dla potrzeb niezbędnych do realizacji procesu rekrutacji zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).`,
          policy2:`Zamknij`,
          alert0: `Formularz został przesłany.`,
          alert1: `Formularz nie został przesłany`,
          error: `To pole jest wymagane`,
          error1: `Zgoda jest wymagana`,
          error2: `Wprowadź prawidłowy plik PDF`,
          error3: `Wprowadź poprawny adres e-mail`,
          lang: `pl`,
        },
      },
      de: {
        translation: {
          home: 'HOMEPAGE',
          form: 'FORMULAR',
          employee: 'FÜR ARBEITNEHMER',
          whatWeDo: 'Über Bavaria Job',
          whatWeDoText: `Wir sind ein Personalvermittlungsunternehmen
          mit mehrjähriger Erfahrung in der Branche.
          Aufgrund einer dynamischen Entwicklung des
          europäischen Arbeitsmarktes wollen wir sowohl
          Arbeitssuchenden als auch Arbeitgebern bei
          dem Rekrutierungsprozess helfen. Wir sind
          keine Arbeitsagentur.`,
          offer: 'Unsere Schwerpunkte',
          offerText: `Unser Hauptziel ist es, Arbeitnehmer und
          Arbeitgeber auf der Grundlage gegenseitiger
          Erwartungen zusammenzubringen.
          Dementsprechend wollen wir unsere
          Dienstleistungen für die beiden Gruppen
          erbringen. Arbeitssuchenden helfen wir eine
          Arbeitsstelle zu finden, die ihren
          Berufsqualifikationen und Lohnerwartungen
          entspricht. Zurzeit erfolgt die Jobsuche
          provisionsfrei, es sei denn, dass die Spezifik der
          gesuchten Stelle einen besonderen
          Komplexitätsgrad aufweist. In solchem Fall
          behalten wir uns das Recht vor, über die
          Bedingungen der zu erbringenden
          Dienstleistung zu verhandeln.`,
          offerJob: 'Angebot für Arbeitgeber',
          offerJobText: `Die Bedingungen für Arbeitgeber, die Mitarbeiter
          suchen, werden in Abhängigkeit vom
          Komplexitätsgrad des Rekrutierungsprozesses
          im Rahmen individueller Verhandlungen
          festgelegt. Unsere Vorgehensweise ist darauf
          abgezielt, die Erwartungen der Jobkandidaten
          mit den Bedürfnissen der potenziellen
          Arbeitgeber zu kombinieren.`,
          offerEmploy: 'Angebot für Arbeitnehmer',
          offerEmployText: `Interessierte Arbeitssuchende werden gebeten,
          ein Formular auszufüllen, in dem sie ihre
          Erwartungen und den Zeitpunkt des möglichen
          Arbeitsbeginns angeben. Auf der Grundlage des
          gemeldeten Bedarfs suchen wir nach
          Arbeitgebern, die geeignete Stellen und
          Arbeitsplätze anbieten.`,
          range: 'Tätigkeitsbereich',
          rangeText: `Mit unserer langjährigen Erfahrung im Bereich
          der Sprach- und Übersetzungsdienstleistungen
          können wir unseren Kunden eine kompetente
          Unterstützung auf dem ausländischen
          Arbeitsmarkt anbieten. Zurzeit sind wir auf dem
          deutschsprachigen Markt (Deutschland,
          Österreich, Schweiz) tätig. In längerer
          Zeitperspektive wollen wir unsere Kontakte auf dem französischsprachigen Markt ausbauen.`,
          companyInfo:`Firmenangaben`,
          companyInfo1:`Das Personalvermittlungsunternehmen SPOT

          mit Sitz in:`,
          companyInfo2:`Żółkiewskiego Str. 6, 43-300 Bielsko-Biała, Polen`,
          companyInfo3:`E-Mail: `,
          companyInfo4:`Telefonnummer: +48 728 542 306`,
          companyInfo5:`Registernummer: 30882`,
          employee0: `Form der Beschäftigung`,
          employee1:`Viele Personen haben bereits Erfahrung mit der
          Arbeit außerhalb ihres Heimatlandes. Allerdings
          empfehlen wir in Hinblick auf die Spezifik des
          deutschen Arbeitsmarktes, sich mit folgenden
          Informationen vertraut zu machen.
          Polnische Arbeitskräfte werden in der Regel
          durch deutsche Arbeitsvermittlungsagenturen auf
          der Grundlage eines Arbeitsvertrags beschäftigt
          und an Bauunternehmen bzw. andere
          Arbeitgeber weitervermittelt. `,
          employee1part: `Bei dieser Form der
          Beschäftigung erhält man eine feste Vergütung,
          arbeitet unter der Anleitung eines Vorgesetzten
          und führt die zugewiesenen, den individuellen
          Qualifikationen entsprechenden Aufgaben aus.
          Der Vorteil ist, dass man neben dem Lohn auch
          die Erstattung von Unterkunftskosten, einen
          Wohnkostenzuschuss, Fahrtkostenzuschuss bzw.
          sonstige Zulagen bekommen kann, was bedeutet,
          dass es möglich ist, einen größeren Teil der
          Vergütung beiseite zu legen. Die Bedingungen
          können von Fall zu Fall unterschiedlich sein und
          werden bei Abschluss des Arbeitsvertrags im
          Einzelnen festgelegt, so dass jeder interessierte
          Bewerber weiß, womit er rechnen kann. An
          dieser Stelle werden andere Formen der
          Beschäftigung (z.B. selbstständige
          Erwerbstätigkeit) nicht erläutert, weil die Anzahl
          der auf diese Weise beschäftigten Arbeitnehmer
          relativ klein ist, und in Einzelfällen bieten wir
          individuelle Lösungen an.`,
          employee2: `Sprachkenntnisse`,
          employee3: `Sehr oft wird nach gewünschten
          Sprachkenntnissen gefragt. Es sollte klar gesagt
          werden, dass Deutschkenntnisse eine wichtige
          Rolle spielen. In der Regel wird im Ausland, wie
          auch in Polen, die Landessprache gesprochen.
          Um eine Arbeit aufnehmen und im Alltag
          zurechtkommen zu können, sollte man in der
          Lage sein, sich in dieser Sprache zu
          verständigen, um zum Beispiel über eine
          Lohnerhöhung zu verhandeln. Das wäre natürlich
          ein Idealfall, aber in der Wirklichkeit brauchen die
          Unternehmen Fachkräfte und sind bereit,
          qualifizierte Mitarbeiter auch ohne
          Sprachkenntnisse einzustellen, wenn sie in einer
          größeren Gruppe, in der eine Person Deutsch
          spricht, die Arbeit ausführen oder wenn der Leiter
          bzw. Vorarbeiter Polnisch, Englisch oder eine
          andere Sprache sprechen kann. Normalerweise
          stehen den Arbeitsvermittlungsagenturen
          polnischsprachige Koordinatoren zur Verfügung,
          die den Arbeitnehmern Unterstützung leisten.
          Mangelnde Sprachkompetenzen müssen also
          kein Hindernis für eine Bewerbung sein, aber die
          Kenntnis der deutschen Sprache stellt einen Vorteil dar und ist sehr hilfreich bei der Arbeit.`,
          employee4: `Alkohol am Arbeitsplatz`,
          employee5: `Ein weiteres Thema ist Alkoholkonsum.
          Niemand achtet selbstverständlich darauf, wie
          erwachsene Personen ihre Freizeit nach der
          Arbeit verbringen. Man sollte sich jedoch
          darüber im Klaren sein, dass Arbeitnehmer oft
          Alkohol nicht in Maßen trinken, was Probleme
          am Unterkunftsort und Arbeitsplatz verursacht.
          Inzwischen haben viele Arbeitgeber Alkoholtests
          eingeführt. Das Arbeiten unter Alkoholeinfluss
          endet mit einer fristlosen Entlassung und zieht
          finanzielle Strafen nach sich. Da es immer
          wieder Probleme mit Alkohol gab und geben
          wird, soll man dies ernst nehmen, um
          Unannehmlichkeiten zu vermeiden. Der beste
          Fachmann wird wegen Alkohol ausscheiden,
          und je früher, desto besser für ihn, weil es
          
          weniger kostet.`,
          employee6: `Privatauto`,
          employee7: `Der Führerschein und ein eigenes Auto sind
          nützlich, denn dadurch ist das tägliche Pendeln
          vom Unterkunftsort zur Arbeit erheblich
          erleichtert. Wenn man über keinen eigenen
          Wagen verfügt, kann man mit anderen
          Personen gemeinsam fahren, so dass dies die
          Möglichkeit zu arbeiten nicht ausschließt.`,
          employee8: `Zusammenfassung`,
          employee9: `Außer Arbeitsvermittlungsagenturen können
          Personen mit beruflichen Qualifikationen und
          entsprechenden Sprachkenntnissen direkt durch
          Arbeitgeber eingestellt werden, und zwar zu den
          gleichen Bedingungen wie Personen, die
          dauerhaft in Deutschland leben. Allerdings
          verdienen sie nicht unbedingt besser, denn sie
          haben kürzere Arbeitszeiten. Eine regelmäßige
          Arbeitszeit beträgt ca. 38-40 Stunden pro
          Woche, während man durch Arbeitsvermittler
          bis zu 60 Stunden pro Woche arbeiten kann.
          Wir behandeln jede Bewerbung individuell und
          suchen nach einer Arbeitsstelle, die den
          Erwartungen und Qualifikationen der
          Kandidaten entspricht. Wir versuchen, den
          Termin des Arbeitsbeginns an Möglichkeiten der
          Bewerber anzupassen. Das ist jedoch nicht
          immer möglich und manchmal muss man
          warten, um eine gute Stelle zu bekommen.`,
          form0: `Gesuchte Stelle`,
          form1: `Bitte die gesuchte Stelle angeben.`,
          form2: `Stelle`,
          form3: `Datum des Arbeitsbeginns`,
          form4: `Bitte das Datum des möglichen Arbeitsbeginns

          angeben.`,
          form5: `Datum`,
          form6: `TT.MM.JJJJ`,
          form7: `Berufsqualifikationen`,
          form8: `Bitte die erworbenen Berufsqualifikationen
          angeben und die Arbeitserfahrung beschreiben
          (Anzahl der gearbeiteten Jahre).`,
          form9: `Berufsqualifikationen`,
          form10: `Zusätzliche Fachkenntnisse`,
          form11: `Bitte die zusätzlichen Qualifikationen und
          Fachkenntnisse angeben (Kurse, Schulungen,
          
          Zertifikate).`,
          form12: `Fachkenntnisse`,
          form13: `Fremdsprachenkenntnisse`,
          form14: `Bitte die Fremdsprachenkenntnisse angeben.`,
          form15: `Englisch`,
          form16: `Sprachniveau`,
          form17: `Grundkenntnisse`,
          form18: `Konversationssicher`,
          form19: `Deutsch`,
          form20: `Italienisch`,
          form21: `Französisch`,
          form22: `Führerschein`,
          form23: `Haben Sie einen Führerschein? (wenn ja, bitte

            die Klasse angeben)`,
          form24: `Ich habe keinen Führerschein.`,
          form25: `B-Klasse`,
          form26: `Sonstige`,
          form27: `Haben Sie ein Privatauto?`,
          form28: `Nein`,
          form28part: `Ja`,
          form29: `Lohnerwartungen`,
          form30: `Bitte die Lohnerwartungen angeben.`,
          form31: `Lohnerwartungen`,
          form32: `Vorname`,
          form33: `Familienname`,
          form34: `Alter`,
          form35: `Telefonnummer`,
          form36: `Bitte die Telefonnummer mit der Vorwahl

          angeben.`,
          form37: `E-Mail-Adresse`,
          form38: `Bitte die E-Mail-Adresse angeben.`,
          form39: `E-Mail`,
          form40: `Lebenslauf (optional)`,
          form41: `Bitte eine PDF-Datei mit dem Lebenslauf

          anhängen.`,
          form42: `DATEI ANHÄNGEN`,
          form43: `Hiermit beauftrage ich die Jobsuche für die
          oben genannte Arbeitsstelle und die
          Übermittlung personenbezogener Daten an
          
          Personalvermittler.`,
          form44: `(Hier klicken, um die Datenschutzbestimmungen

            zu lesen)`,
          form45: `ZUSTIMMEN`,
          form46: `SENDEN`,
          policy0: `Datenschutzbestimmungen`,
          policy1:`Hiermit stimme ich der Verarbeitung meiner personenbezogenen Daten durch die Firma
          SPOT mit Sitz in: 43-300 Bielsko-Biała, Żółkiewskiego 6, für die für das
          Einstellungsverfahren erforderlichen Zwecke gemäß der Verordnung (EU) 2016/679 des
          Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher
          Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur
          Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung) zu.`,
          policy2:`Exit`,
          alert0: `Das Formular wurde abgeschickt.`,
          alert1: `Das Formular wurde nicht abgeschickt.`,
          error: `Dieses Feld ist erforderlich`,
          error1: `Zustimmung ist erforderlich`,
          error2: `Geben Sie eine gültige PDF-Datei ein`,
          error3: `Geben Sie eine gültige E-Mail Adresse ein`,
          lang: `de`,
        },
        
      },
    },
    lng: 'pl',
    supportedLngs: ['pl', 'de'], 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
