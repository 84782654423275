import React from "react";
import "../styles/Home.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import myLogo3 from '../images/logo3.png'






  




const Company = () => {
 
  return (
    <Box>
      <Typography variant="h5" color="text.primary" gutterBottom align="center" sx={{mt: {lg: "3vh", md: "3vh", xs:"2vh"},display: 'flex', justifyContent: 'center', width: '100%'}}>
      <img
          
          style={{display : 'block',}}
            height={50}
            width={71}
            src={myLogo3} alt="Logo"></img>
      </Typography>

      <Typography
        fontWeight={300}
        variant="h4"
        color="text.primary"
        gutterBottom
        align="center"
        sx={{mt: '2vh', px: { lg:"20vw", md:"10vw", xs:"3vw",}}}
      >
       Für Arbeitgeber
      </Typography>


      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
       
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"},
         
        }}
      >
        
      Wir sind ein Personalvermittlungsunternehmen,
das im Auftrag von Arbeitgebern und
Privatpersonen Arbeitskräfte für verschiedene
Einsätze findet. Mit unseren Kontakten und
Erfahrungen können wir Mitarbeiter aus
mehreren Branchen rekrutieren, mit
besonderem Fokus auf: 
<br></br>- Baufacharbeiter (Sanitär- und
Heizungsinstallateure, Lüftungsinstallateure,
Elektriker, Verputzer, Zimmerleute und Tischler,
Trockenbauer, Maurer, Schweißer, Bodenleger,
Baumaschinenführer u.a.),
<br></br>- Kfz-Facharbeiter (Mechaniker, Elektriker,
Klempner, Lackierer),
<br></br>- Lagerfacharbeiter (Lagermitarbeiter mit
unterschiedlichen Qualifikationen),
<br></br>- Paketzusteller,
<br></br>- Kfz-Fahrer,
<br></br>- andere Fachkräfte, je nach Bedarf der
Arbeitgeber.
Wir bieten auch Jobsuche für hochqualifizierte
Fachkräfte aus solchen Bereichen wie: IT,
Medizin, Bauprojektierung bzw. anderen, an.
      </Typography>
    
      
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
        
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
      Wir verfügen über eine ständig wachsende
Datenbank mit Arbeitssuchenden. Im Hinblick auf
die Dynamik der Rekrutierungsprozesse werben
wir Arbeitskräfte in mehreren Berufen auf
kontinuierlicher Basis an. Aufgrund einer hohen
Nachfrage nach Arbeitnehmern aus den neuen
EU-Ländern wird es allerdings immer schwieriger,
Fachkräfte zu finden, insbesondere mit
entsprechenden Fremdsprachenkenntnissen. In
vielen Fällen müssen wir einen
maßgeschneiderten Rekrutierungsprozess
vorbereiten und abwickeln, was eine gewisse Zeit
und Kosten in Anspruch nehmen kann. Je nach
dessen Komplexitätsgrad wird die Höhe unserer
Provision jeweils individuell festgelegt. Der Betrag wird fällig, wenn der
Arbeitnehmer die Arbeit bei dem Auftraggeber
aufgenommen hat. Die Zahlung kann in einer
bzw. in zwei Raten erfolgen. Bei langfristiger
Beschäftigung (länger als 3 Monate) ist es
möglich, die Provision als Teil des Stundenlohns
festzulegen. Nach Bedarfsanmeldung wird dem
Auftraggeber innerhalb von 2 Arbeitstagen ein
Kostenvoranschlag für die Rekrutierung der
gewünschten Fachkräfte vorgelegt. Nach einer
schriftlichen Auftragsbestätigung senden wir die
Angaben der verfügbaren Kandidaten, die dem
Bedarf des Arbeitgebers entsprechen. Der
Rekrutierungsprozess wird fortgesetzt, bis die
Kandidaten akzeptiert und eingestellt werden. Die
Unterlagen der Kandidaten werden nach
vorheriger Absprache übergeben. Der
      </Typography>
      
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
       
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "2vh", md: "2vh", xs: "2vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
      Auftraggeber führt den Einstellungsprozess in
eigener Verantwortung durch, gegebenenfalls mit
unserer Unterstützung, sofern dies im Rahmen
des Auftrags vereinbart wird.
      </Typography>
     
      <Typography
        fontWeight={300}
        variant="h6"
        color="text.primary"
        
        
        lineHeight={1.35}
        sx={{
          px: { lg: "20vw", md: "10vw", xs: "5vw" },
          pt: { lg: "2vh", md: "2vh", xs: "2vh" },
          pb: { lg: "4vh", md: "4vh", xs: "4vh" },fontSize:{ lg:"1.3rem", md:"1.3rem", xs:"1rem"}, textAlign: "justify"
        }}
      >
       Mit unserer langjährigen Erfahrung im Bereich
der Sprach- und Übersetzungsdienstleistungen
können wir unseren Partnern
Sprachunterstützung bei der Einstellung und
Einweisung neuer Mitarbeiter anbieten. Dies
betrifft sowohl die Unterstützung durch einen
Dolmetscher am Arbeitsplatz für die Dauer der
Einarbeitung, als auch die Übersetzung von
Dokumenten oder Unterstützung bei
sprachlichen Problemen aller Art und erfolgt auf
Grundlage einer detaillierten Spezifikation und
eines separaten Angebots. Wegen der
Komplexität des Rekrutierungsprozesses stellen
wir Ihnen unsere Website www.bavaria-job.com mit einem <a href="/company_form">Formular</a> zur Verfügung, mit dem Sie Ihre Bedarfsanmeldung sicher und bequem
abgeben können. Es umfasst die wichtigsten
Angaben über die von Ihnen angebotenen
Beschäftigungsbedingungen sowie über die von
Ihnen an potenzielle Kandidaten gestellten
Anforderungen. Das Formular soll dabei
behilflich sein, Ihren Bedarf zu ermitteln und den
gesamten Rekrutierungsprozess zu optimieren,
indem die gegenseitigen Erwartungen
mitaneinander kombiniert werden. Auch die
Arbeitssuchenden werden eingeladen, das
entsprechende Formular mit den erforderlichen
Datenschutzklauseln und
Einwilligungserklärungen auszufüllen, damit wir
ihre Angaben an die interessierten Arbeitgeber
weiterleiten können. Durch die teilweise
Automatisierung des Rekrutierungsprozesses
können die Daten effizient nach gewünschten
Kriterien ausgewertet und an unsere Partner
weitergegeben werden. In Zukunft werden die
interessierten Unternehmen den Zugang zu der
Datenbank erhalten können, und zwar zu den
Bedingungen, die in einem getrennten Vertrag

festgelegt werden.
        
      </Typography>



     
    </Box>
  );
};

export default Company;
