import * as React from "react";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';




  



export default function Footer() {
  const { t } = useTranslation();
  const location = useLocation();

  // Function to determine if the current page requires a different email address
  const isSpecialPage = () => {
    const specialPages = ['/employer', '/company_form'];
    return specialPages.some(page => location.pathname.includes(page));
  };
  return (
    <Box
      component="footer"
      sx={{
        boxShadow: 4,
        backgroundColor: '#1976d2',
        p: 2,
        
      }}
    >
      <Container maxWidth="lg">
        <Box>
          <Typography 
            variant="h6"
            color="white"
            gutterBottom
            align="center"
          >
           {t('companyInfo')}
          </Typography>
          <Typography variant="body2" color="white" align="center">
          {t('companyInfo1')}
          </Typography>
          <Typography variant="body2" color="white" align="center">
          {t('companyInfo2')}
          </Typography>
          <Typography variant="body2" color="white" align="center">
          {t('companyInfo3')} {isSpecialPage() ? "biuro@bavaria-job.com" : "rekrutacja@bavaria-job.com"}
          </Typography>
          <Typography variant="body2" color="white" align="center">
          {t('companyInfo4')}
          </Typography>
          <Typography variant="body2" color="white" align="center">
          {t('companyInfo5')}
          </Typography>
          
        </Box>
        <Box mt={"5vh"}>
          <Typography variant="body2" color="white" align="center">
            {"Copyright © "}
            <Link color="inherit" component={RouterLink} to="/">
              Bavaria Job
            </Link>{" "}
            {new Date().getFullYear()}
            {""}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
